<template>
    <div class="container">
        <h2 class="text-center">专栏文章</h2>
        <div class="grid-4_xs-1_sm-2_md-2" v-if="tags.length">
            <div v-for="(tag, index) in tags" :key="index" class="col">
                <router-link :to="`/tag/${tag.name}`">
                    <vs-card type="3" class="center">
                        <template #title>
                            <h3>{{ tag.name }}</h3>
                        </template>
                        <template #text>
                            <p>
                                {{ tag.count }} post{{ `${tag.count >= 1 ? "s" : ""}` }}
                            </p>
                        </template>
                    </vs-card>
                </router-link>
            </div>
        </div>
        <Nothing v-else />

    </div>
</template>

<script>
import Nothing from '@/components/Nothing.vue'
export default {
  name: 'ViewSeries',
  components: {
    Nothing
  },
  data () {
    return {
      genreName: this.$route.params.genreName,
      tags: []
    }
  },
  watch: {
  },
  methods: {
  },
  created () {
    this.request
      .GET('/genre/getAllTags', { genreName: this.genreName })
      .then((res) => {
        if (res.data) {
          this.tags = res.data
        }
      })
  }
}
</script>
